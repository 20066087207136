<template>
  <div class="s-bg-white">
    <div :class="isMobile? '':'s-ht-400-min'">
      <div class="s-content-bg s-flex">
        <div :class="`s-main-container ${isMobile? 's-fullWidth s-ptb-20':'s-mtb-auto'}`">
          <div>
            <h2 v-if="!isMobile" class="s-text-white">{{$t('feedbackForm.title')}}</h2>
            <h5 v-else class="s-text-white s-text-center">{{$t('feedbackForm.title')}}</h5>
          </div>
        </div>
      </div>
    </div>
    <div :class="`s-main-container s-ht-700 ${isMobile? 's-ptb-20':'s-ptb-60'}`">
      <div class="s-map-container  ">
          <div class="s-map-header">
			<div class="s-map-menu" @click="sidebar = !sidebar" v-if="!sidebar">
				<div class="s-square-24">
					<img :src="require('@/assets/icons/primary/icon-menu.svg')" >
				</div>
			</div>
			<div class="s-map-sidebar" v-if="sidebar">
				<div class="s-center-flex">
					<div>
						<h5>{{$t('feedbackForm.feedbackFromPeoples')}}</h5>
					</div>
					<div class="s-square-24 s-ml-auto s-cursor-pointer" @click="sidebar = !sidebar">
						<img :src="require('@/assets/icons/primary/icon-menu.svg')" >
					</div>
				</div>
				<div v-for="(i, index) in cfc" :key="`cfc-${index}`">
					<div class="s-tag-green-40 s-mt-16 s-ptb-16">
						<h6 v-if="lang == 'id'"><span>{{i.code}} . </span>{{i.location_id}}</h6>
						<h6 v-else><span>{{i.code}} . </span>{{i.location_en}}</h6>
					</div>
					<div v-if="i.feedback.length > 0">
						<p class="s-weight-600">Feedbacks</p>
						<div v-for="(j, index) in i.feedback" :key="`feedback-${index}`" class="s-mt--16 s-p-5">
							<div class="s-center-flex">
								<p class="s-text-italic s-text-primary s-weight-600">{{j.creator.name}}</p> 
								<p class="s-mlr-5">|</p>
								<div class="s-flex s-wrap s-ht-50-max s-overflow-hidden">
									<div class="s-tag-secondary">
										<p v-if="lang=='id'" class="s-text-white" style="font-size: 10px">{{j.hazard_idn}}</p>
										<p v-else class="s-text-white" style="font-size: 10px">{{j.hazard_en}}</p>
									</div>
								</div>
							</div>
							<p class="s-ml-5 s-mt--10">{{j.comment}}</p>
							<div class="s-ml-5 s-bg-page-background-2 s-radius-10 s-ptb-5 s-mb-10" v-for="(k, index) in replies[j.id]" :key="`reply-${index}`">
								<div class="s-plr-16">
									<p class="s-weight-600">{{k.created_by}}</p>
									<p>{{k.reply}}</p>
								</div>
							</div>
							<div class="s-flex s-ml-5 s-mt--10">
								<div v-if="!replies[j.id]" class="r-secondary-textOnly-btn s-cursor-pointer" style="font-size: 12px" @click.prevent="loadReply(j.id)">{{$t('feedbackForm.viewReply')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="s-map-zoom s-center-flex s-ml-auto s-mr-16">
				<div class="s-square-20 s-p-10 s-cursor-pointer s-hover-white" @click.prevent="zoomOut()">
					<img class="s-fullWidth " :src="require('@/assets/icons/primary/zoom-out.svg')">
				</div>
				<div class="s-square-20 s-bg-primary s-p-10 s-cursor-pointer s-hover-primary" @click.prevent="zoomIn()">
					<img class="s-fullWidth" :src="require('@/assets/icons/white/zoom-in.svg')">
				</div>
			</div>
          </div>
        <div class="s-map-image">
          <Map :height="height" :width="width" />
        </div>
        
      </div>
    </div>
    <Modal v-if="modal == 'success'" size="small" title="Success">
      <template #modalContent>
        <p class="s-text-center">Thank you for submitting feedback</p>
        <p class="s-text-center">
          Hopefully your feedbacks can be useful for us
        </p>
      </template>
    </Modal>
    <Modal v-if="modal == 'failed'" size="small" header="error" title="Failed">
      <template #modalContent>
        <p class="s-text-center">Please Try Again</p>
        <p class="s-text-center">
          Error occured, please fill data and submit again
        </p>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/helper/Modal';
import { mapActions, mapGetters } from 'vuex';
import Map from '@/components/public/map';
export default {
  components: {
    Modal,
    Map,
  },
  data: () => ({
    modalLumbini: false,
    modalBorobudur: false,
    lumbiniValue: '',
    borobudurValue: '',
    width: 1000.8898,
    height: 700.2756,
	sidebar: false,
	replyData: {}
  }),
  computed: {
    ...mapGetters({
		modal: 'getModal',
		isMobile: 'getIsMobile',
		cfc: 'cfc/getFeedback',
		lang: 'locale/getCurrentLocale', 
		reply: 'cfc/getReply',
		replies: 'cfc/getReplies'
    }),
  },
  mounted(){
		this.initData()
  },
  methods: {
    ...mapActions({
		setModal: 'setModal',
		setFeedbackData: 'cfc/setFeedbackPublicData',
		setReply: 'cfc/setFeedbackPublicReply',
		setReplies: 'cfc/setReplies'
    }),
    submit() {
		this.setModal('success');
    },
    async initData(){
      await this.setFeedbackData()
    },
	async loadReply(id){
		await this.setReply(id)	
		this.replyData[id] = this.reply
		this.setReplies(this.replyData)
	},
    zoomIn(){
      this.width = this.width + 200
      this.height = this.height + 200
    },
    zoomOut(){
      this.width = this.width - 200
      this.height = this.height - 200
    }
  },
};
</script>
